.title {
    font-family: 'Raleway';
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 30px;
/* identical to box height, or 125% */
margin-top: 120px;
text-align: center;
letter-spacing: 0.01em;

color: #001E3C;
}


.name {
    margin-top: 48px;
    width: 500px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.dob {
    margin-top: 64px;
    display:flex;
    flex-direction: row;
    width: 500px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.select {
    min-width: 120px!important;
}
.button {
    text-align: center;
    margin-top: 48px;
}

.postcode-fields {
    display: flex;
    flex-direction: column;
    width: 226px;
    margin-left: auto;
    margin-right: auto;
}

.postcode-fields > div {
    margin-top: 24px;
}
.postcode-fields > p {
    margin-top: 24px;
}

.fab-container {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.floating-buttons {
    display: flex;
    flex-direction:row;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
}

.floating-buttons > div {
    margin: 12px;
}

.floating-buttons > label  {
    border-radius: 100px;
    width:fit-content;
    margin: 8px;
    margin-right: auto;
    margin-left: auto;
}


.some > label {
    background:#001E3C;
    border-radius: 100px;
}
.some > label > .MuiTypography-body1 {
    color: white !important;
}
.else > label{
    background: #F2F2F2!important;
    border-radius: 100px;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #ffff!important
}

.MuiFormControlLabel-label {
    padding-right: 16px;
}

.add-more {
    text-align: center;
    padding-top: 24px;
}

.gas-electricity-dropdown {
    display: flex;
    flex-direction: row;
   justify-content:center;
   margin-top: 48px;
}

.gas-electricity-dropdown > div {
    width: 226px;
    margin-right: 12px;
}

.gas-electricity-textfield {
    display: flex;
    flex-direction: row;
   justify-content:center;
}

.gas-electricity-textfield > div {
    width: 226px;
    margin-right: 12px;
    margin-top: 24px;
}

.skip {
    margin-top: 64px;
   text-align: center;
}

.skip > button {
    margin-right:auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}

.create-acc {
    display: flex;
    flex-direction: column;
}

.create-acc > div {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    margin-top: 12px;
}

.onboarding-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.congrats-container {
    position: fixed;
    top: 0;
    height: 100vh;
    background-color: #00446D;
    width: 100%
}

@media only screen and (max-width: 600px){
    .name {
        display: flex;
        flex-direction: column;
        width: auto;
        width: 100%;
    }

    .name > div {
        margin-top: 24px;
    }

    .dob {
        margin-top: 24px;
        width: 100%;
        justify-content: start;
    }

    .dob > div {
        margin-right: 8px;
    }
    .select {
        max-width: 107px;
    }

    .fab-container {
        width: auto;
    }
    .floating-buttons > div {
        margin:8px;
    }
}